import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import { toast, ToastContainer } from "react-toastify";
import SaveTo from "../modal/SaveTo";
import {
  uploadEvent,
  uploadImage,
} from "../../services/EventService/EventService";
import UploadDetails from "../modal/UploadDetails";
import { connect } from "react-redux";

function UploadEvents(props) {
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [company, setCompany] = useState("");
  const [about, setAbout] = useState("");
  const [value, setValue] = useState("");
  let errorsObj = { title: "", image: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  console.log(props.responseData[0], "data")
  const imageResponseData = props.responseData[0];
  const newId = props.newId;
  console.log(imageResponseData?.emails,imageResponseData?.organization,"hello 1234")
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (title === "") {
      errorObj.title = "This field is required!";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    uploadEvent(title)
      .then((response) => {
        // notifyTopRight("Upload Successfully.");
        // setTitle("");
        setLoader(false);
        console.log(response, "api resp");
        setData(response.data.data.response[0].emails);
        setCompany(response.data.data.response[0].organization);
        setAbout(response.data.data.response[0].description);
        setId(response.data.data._id);
        setImage("");
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error.response.data.message);
        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 401) {
          localStorage.clear("handshookDetails");
          props.history.push("/login");
        }
      });
  }

useEffect(() => {
  setData(imageResponseData?.emails);
  setCompany(imageResponseData?.organization);
  setAbout(imageResponseData?.description);
  setId(newId);
  console.log(data,company,about,id,"data aaya ya nahi")

}, [props.responseData]);
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Upload" motherMenu="Upload" />
      <div className="d-flex align-items-center justify-content-center">
        <div className="col-lg-5 col-md-5 mb-5">
          <div className="authincation-content text-black p-5">
            <div className="mb-4">
              <h3 className="mb-1 font-w600 text-black ">Upload Details</h3>
            </div>

            <form >
              <div className="form-group">
                <label className="mb-2 ">
                  <strong className="">Image</strong>
                </label>
                <input
                  type="file"
                  className="form-control"
                  // value={image}
                  onChange={(e) => setImage(e.target.files[0])}
                  placeholder="Enter image"
                />
                {/* {errors.image && (
                  <div className="text-danger fs-12">{errors.image}</div>
                )} */}
              </div>

              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={() => setShowModal(true)}
                >
                  Upload
                </button>
              </div>
            </form>
          </div>
        </div>
        <h3>OR</h3>
        <div className="col-lg-5 col-md-5 mb-5">
          <div className="authincation-content text-black p-5">
            <div className="mb-4">
              <h3 className="mb-1 font-w600 text-black ">Upload Details</h3>
            </div>

            <form onSubmit={onSubmit}>
              <div className="form-group">
                <label className="mb-2 ">
                  <strong className="">Domain Name</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter name"
                />
                {errors.title && (
                  <div className="text-danger fs-12">{errors.title}</div>
                )}
              </div>

              <div className="text-center">
                <button type="submit" className="btn btn-primary btn-block">
                  Upload
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Col>
        <Card>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>USERNAME</strong>
                  </th>
                  <th>
                    <strong>URL</strong>
                  </th>
                  <th>
                    <strong>COMPANY</strong>
                  </th>
                  <th>
                    <strong>ABOUT</strong>
                  </th>

                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {item.first_name} {item.last_name}
                    </td>
                    <td>{item.linkedin}</td>
                    <td>{company}</td>
                    <td>{about}</td>
                    <td>
                      <button
                        className="btn btn-primary py-2 fs-12"
                        onClick={() => {
                          setValue(item.value);
                          setShow(true);
                        }}
                      >
                        Save
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {data?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </Col>
      <SaveTo show={show} id={id} value={value} onHide={() => setShow(false)} />
      {loader && <Spinner />}
      <UploadDetails show={showModal} image={image} onHide={()=>setShowModal(false)}/>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    responseData:state.eventData.responseData,
    newId:state.eventData.id,
  };
};
export default connect(mapStateToProps)(UploadEvents);