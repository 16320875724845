import React from 'react'
import { Loader, Placeholder } from 'rsuite';

  
export default function Spinner() {
    return (
   
        <div style={{ margin: 200}}> 
        {/* <Placeholder.Paragraph rows={8} /> */}
        <Loader backdrop content="loading..." size="lg"  center/>
      </div>
    );
}