import { SET_EVENT_DETAILS_ACTION, SET_ID_ACTION, SET_RESPONSE_ACTION } from "../actions/EventDetailsAction";

const initialState = {
    data: [],
    responseData: [],
    id:""
};

export default function EventDetailsReducer(state = initialState, action) {
    if (action.type === SET_EVENT_DETAILS_ACTION) {
        return {
            ...state,
            data: action.payload,
        };
    }
    if (action.type === SET_RESPONSE_ACTION) {
        return {
            ...state,
            responseData: action.payload,
        };
    }
    if (action.type === SET_ID_ACTION) {
        return {
            ...state,
            id: action.payload,
        };
    }
    
    return state;
}
