import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { eventsList } from "../../services/EventService/EventService";
import { setEventDetailsAction } from "../../store/actions/EventDetailsAction";
import Spinner from "../common/Spinner";
import PageTitle from "../layouts/PageTitle";

export default function Events(props) {
  const [loader, setLoader] = useState(false);
  const [events, setEvents] = useState([]);
  const dispatch = useDispatch();
  function getData() {
    setLoader(true);
    eventsList()
      .then((response) => {
        console.log(response.data.data);
        setEvents(response.data.data);

        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("handshookDetails");
          props.history.push("/login");
        }
      });
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <PageTitle activeMenu="Events" motherMenu="Events" />
      <div className="row">
        {events?.map((item, i) => (
          <div
            key={i}
            className="col-6 mb-4"
            onClick={() => {
              dispatch(setEventDetailsAction(item.profiles));
              props.history.push("/events-details");
            }}
          >
            <h3 className="p-3 bg-orange ">{item.eventName}</h3>
          </div>
        ))}
      </div>
      {loader && <Spinner />}
    </div>
  );
}
