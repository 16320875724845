export const UPLOAD_EVENTS = 'upload';

export const GET_EVENTS = 'events';

export const SAVE_TO = 'save';

export const UPLOAD_IMAGE = 'ocrText';

export const UPDATE_TEXT = 'edit/ocrText';


