import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { eventsList, saveTo } from "../../services/EventService/EventService";
import Spinner from "../common/Spinner";

export default function SaveTo({ show, id, value, onHide }, props) {
  const [type, setType] = useState("");
  const [loader, setLoader] = useState(false);
  const [eventName, setEventName] = useState("");
  const [eventId, setEventId] = useState("");
  const [events, setEvents] = useState([]);
  // console.log(eventId, "id");
  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  function getData() {
    setLoader(true);
    eventsList()
      .then((response) => {
        console.log(response.data.data);
        setEvents(response.data.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("handshookDetails");
          props.history.push("/login");
        }
      });
  }

  function saveInEvent() {
    setLoader(true);
    saveTo(id, value, eventName, eventId)
      .then((response) => {
        console.log(response);
        setLoader(false);
        onHide();
        notifyTopRight("Saved Successfully.");
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("handshookDetails");
          props.history.push("/login");
        }
      });
  }
  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal className="modal fade" show={show}>
        <div className="" role="document">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Save to</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => onHide()}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">
                      Create New Event
                    </label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                        placeholder="Enter name"
                      />
                      {/* {errors.type && (
                      <div className="text-danger fs-12">{errors.type}</div>
                    )} */}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">
                      Save to privious events
                    </label>
                    <div className="row">
                      {events?.map((item, i) => (
                        <div
                          className="col-6 d-flex justify-content-between align-items-baseline"
                          key={i}
                        >
                          <label>{item.eventName}</label>
                          <input
                            type="checkbox"
                            // checked={eventId}
                            onChange={(e) => setEventId(item._id)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-info"
                onClick={saveInEvent}
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => onHide()}
                className="btn btn-danger"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Cancel
              </button>
            </div>
          </form>
        </div>
        {loader && <Spinner/>}
      </Modal>
    </>
  );
}
