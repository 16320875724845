export const SET_EVENT_DETAILS_ACTION = '[set data action] set data action';

export const SET_RESPONSE_ACTION = '[set response data action] set response data action';

export const SET_ID_ACTION = '[set id action] set id action';



export function setEventDetailsAction(data) {
    return {
        type: SET_EVENT_DETAILS_ACTION,
        payload: data,
    };
}
export function setResponseAction(data) {
    return {
        type: SET_RESPONSE_ACTION,
        payload: data,
    };
}
export function setIdAction(data) {
    return {
        type: SET_ID_ACTION,
        payload: data,
    };
}
