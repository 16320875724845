import React from "react";
import { Card, Table } from "react-bootstrap";
import { connect } from "react-redux";
import PageTitle from "../layouts/PageTitle";

function EventsDetails(props) {
  const data = props.eventdata;
  console.log(props.eventdata,"events details")
  return (
    <div>
      <PageTitle activeMenu="Event details" motherMenu="Events" />
      <Card>
        <Card.Body>
          <Table responsive>
            <thead style={{ color: "black" }}>
              <tr>
                <th>
                  <strong>USERNAME</strong>
                </th>
                <th>
                  <strong>COMPANY</strong>
                </th>
                <th>
                  <strong>URL</strong>
                </th>
                <th>
                  <strong>ABOUT</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item,i)=>(
                <tr key={i}>
                <td>{item?.profile?.first_name} {item?.profile?.last_name}</td>
                <td>{item.organization}</td>
                <td>{item.profile?.linkedin}</td>
                <td>{item.description}</td>
              </tr>
              ))}
              
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    eventdata: state.eventData.data,
   
  };
};
export default connect(mapStateToProps)(EventsDetails);