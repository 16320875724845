import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { editOcrText, uploadImage } from "../../services/EventService/EventService";
import { setIdAction, setResponseAction } from "../../store/actions/EventDetailsAction";
import Spinner from "../common/Spinner";

export default function UploadDetails({ show, image, onHide, props }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [web, setWeb] = useState("");
const [id, setId] = useState("");
  function onImageSubmit() {
    setLoader(true);
    uploadImage(image)
      .then((response) => {
        setLoader(false);
        console.log(response, "api resp");
        setFirstName(response.data.data.firstName);
        setLastName(response.data.data.lastName);
        setCompany(response.data.data.companyName);
        setPhone(response.data.data.phoneNumber);
        setEmail(response.data.data.email);
        setWeb(response.data.data.webpage);
        setId(response.data.data._id);
      })
      .catch((error) => {
        setLoader(false);

        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 401) {
          localStorage.clear("handshookDetails");
          props.history.push("/login");
        }
      });
  }
  function onFormSubmit(e) {
   
    e.preventDefault();
    setLoader(true);
    editOcrText(id,firstName,lastName,company,phone,email,web)
      .then((response) => {
        setLoader(false);
        onHide();
        dispatch(setResponseAction(response.data.data.response));
        dispatch(setIdAction(response.data.data._id));
        console.log(response, "api resp");
        
      })
      .catch((error) => {
        setLoader(false);

        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 401) {
          localStorage.clear("handshookDetails");
          props.history.push("/login");
        }
      });
  }
  
  useEffect(() => {
    if (show) {
      
      onImageSubmit();
    }
  }, [show]);
  return (
    <>
      <Modal className="modal fade" show={show} centered>
        <div className="">
          <form onSubmit={onFormSubmit}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Upload Details</h4>
              <button
                type="button"
                className="btn close"
                onClick={() => onHide()}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">First Name</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="Enter first name"
                      />
                      {/* {errors.type && (
                      <div className="text-danger fs-12">{errors.type}</div>
                    )} */}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Last Name</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Enter last name"
                      />
                      {/* {errors.type && (
                      <div className="text-danger fs-12">{errors.type}</div>
                    )} */}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Company Name</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        placeholder="Enter company name"
                      />
                      {/* {errors.type && (
                      <div className="text-danger fs-12">{errors.type}</div>
                    )} */}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Email</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter email"
                      />
                      {/* {errors.type && (
                      <div className="text-danger fs-12">{errors.type}</div>
                    )} */}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Phone Number</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Enter phone number"
                      />
                      {/* {errors.type && (
                      <div className="text-danger fs-12">{errors.type}</div>
                    )} */}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Web Page</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        value={web}
                        onChange={(e) => setWeb(e.target.value)}
                        placeholder="Enter web name"
                      />
                      {/* {errors.type && (
                      <div className="text-danger fs-12">{errors.type}</div>
                    )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-info"
                // onClick={saveInEvent}
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => onHide()}
                className="btn btn-danger"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Cancel
              </button>
            </div>
          </form>
        </div>
        {loader && <Spinner />}
      </Modal>
    </>
  );
}
