import instance from "../Instance";
import { GET_EVENTS, SAVE_TO, UPDATE_TEXT, UPLOAD_EVENTS, UPLOAD_IMAGE } from "./ApiEndPoints";

export function uploadEvent(title) {
  const data = localStorage.getItem("handshookDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = {
    name: title,
  };
  return instance.post(UPLOAD_EVENTS, postData, {
    headers: myHeaders,
  });
}
export function uploadImage(image) {
  // console.log(image,"from api function")
  const data = localStorage.getItem("handshookDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = {
    image,
  };
  var formdata = new FormData();
  
  formdata.append("image", image);
  return instance.post(UPLOAD_IMAGE, formdata, {
    headers: myHeaders,
  });
}
export function editOcrText(id,firstName,lastName,company,phone,email,web) {
  console.log(id,"from api function")
  const data = localStorage.getItem("handshookDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = {
    firstName,lastName,companyName:company,phoneNumber:phone,email,webpage:web
  };
 
  return instance.put(UPDATE_TEXT +`?_id=${id}`, postData, {
    headers: myHeaders,
  });
}
export function saveTo(id,value,eventName,eventId) {
    const data = localStorage.getItem("handshookDetails");
    const myHeaders = {
      Authorization: `Bearer ${data}`,
    };
   const postData = {name:eventName,eventID:eventId}
  
   return instance.post( SAVE_TO +`?id=${id}&value=${value}`,postData,{
      headers: myHeaders,
    });
  }
export function eventsList() {
    const data = localStorage.getItem("handshookDetails");
    const myHeaders = {
      Authorization: `Bearer ${data}`,
    };
    return instance.get(GET_EVENTS, {
      headers: myHeaders,
    });
  }

